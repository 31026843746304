import { cloneDeep, findKey, get, isEmpty } from 'lodash';

const OLD_IMAGE_TYPE = 'IMAGE';
const IMAGE_TYPE = 'wix-draft-plugin-image';
const GALLERY_TYPE = 'wix-draft-plugin-gallery';
const GIPHY_TYPE = 'wix-draft-plugin-giphy';
const ALL_IMAGE_ENTITY_TYPES = [OLD_IMAGE_TYPE, IMAGE_TYPE, GALLERY_TYPE];

const getCoverFromImage = entity => get(entity, 'data.src');
const getCoverFromGallery = entity => {
  const item = get(entity, 'data.items[0]');
  return item
    ? {
        file_name: item.url,
        width: item.metadata.width,
        height: item.metadata.height,
      }
    : {};
};

const getPostImageCover = post => {
  const contentState = get(post, 'content', {});
  const entities = contentState.entityMap || {};
  const key = findKey(entities, entity => ALL_IMAGE_ENTITY_TYPES.indexOf(entity.type) > -1);
  const entity = entities[key];

  return entity ? cloneDeep(getCoverFromImage(entity) || getCoverFromGallery(entity)) : undefined;
};
const getPostGiphyCover = post => {
  const contentState = get(post, 'content', {});
  const entities = contentState.entityMap || {};
  const key = findKey(entities, entity => GIPHY_TYPE === entity.type);
  const entity = entities[key];
  const data = get(entity, 'data.gif');

  return entity ? cloneDeep(data) : undefined;
};

const OLD_VIDEO_EMBED_TYPE = 'VIDEO-EMBED';
const VIDEO_EMBED_TYPE = 'wix-draft-plugin-video';
const ALL_VIDEO_EMBED_ENTITY_TYPES = [OLD_VIDEO_EMBED_TYPE, VIDEO_EMBED_TYPE];

const OEMBED_PATH = {
  [OLD_VIDEO_EMBED_TYPE]: 'data.oembed',
  [VIDEO_EMBED_TYPE]: 'data.metadata.oembed',
};

const getCoverFromOembed = entity => {
  return get(entity, `${OEMBED_PATH[entity.type]}.thumbnail_url`)
    ? get(entity, OEMBED_PATH[entity.type])
    : undefined;
};

const getCoverFromVideo = (entity, { resizeThumbnail, getVideoUrl }) => {
  if (!(resizeThumbnail && getVideoUrl)) {
    return undefined;
  }

  const video = get(entity, 'data.src');
  const thumbnail = get(entity, 'data.src.thumbnail');

  if (!thumbnail) {
    return undefined;
  }

  const resized = resizeThumbnail(thumbnail.pathname, thumbnail.width, thumbnail.height);

  return {
    thumbnail_url: resized.url,
    thumbnail_height: resized.width,
    thumbnail_width: resized.height,
    video_url: getVideoUrl(video.pathname),
  };
};

const getPostVideoEmbedCover = (post, helpers = {}) => {
  const contentState = get(post, 'content', {});
  const entities = contentState.entityMap || {};
  const key = findKey(
    entities,
    entity =>
      ALL_VIDEO_EMBED_ENTITY_TYPES.includes(entity.type) &&
      (get(entity, `${OEMBED_PATH[entity.type]}.thumbnail_url`) ||
        get(entity, 'data.src.thumbnail')),
  );
  const entity = entities[key];

  return entity
    ? cloneDeep(getCoverFromOembed(entity) || getCoverFromVideo(entity, helpers))
    : undefined;
};

const getCustomCoverImage = post => {
  const { isEnabled = true, isCustom = true, src } = get(post, 'coverImage', {});
  return isEmpty(src)
    ? { isEnabled, isCustom: false }
    : {
        isEnabled,
        imageMetadata: cloneDeep(src),
        isCustom,
      };
};

const getResolvedCoverImage = post => {
  const { isResolved, ...props } = post.coverImage || {};
  return isResolved === true ? props : undefined;
};

const getPostCover = (post, helpers = {}) => {
  const resolvedCoverImage = getResolvedCoverImage(post);
  if (resolvedCoverImage) {
    return resolvedCoverImage;
  }

  // eslint-disable-next-line prefer-const
  let { imageMetadata, videoMetadata, isCustom, isEnabled } = getCustomCoverImage(post);

  if (!imageMetadata) {
    imageMetadata = getPostImageCover(post);
  }

  if (!imageMetadata) {
    videoMetadata = getPostVideoEmbedCover(post, helpers);
  }

  return {
    shouldRender: Boolean(imageMetadata || videoMetadata) && isEnabled,
    imageMetadata,
    videoMetadata,
    src: imageMetadata || get(videoMetadata, 'thumbnail_url'),
    isEnabled,
    isCustom,
  };
};

export {
  getPostImageCover,
  getPostVideoEmbedCover,
  getCustomCoverImage,
  getPostCover,
  getResolvedCoverImage,
  getPostGiphyCover,
};
