import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { MODAL_TYPE_DEMO_MODE } from '../modals/demo-modal/demo-modal-type';
import Button from '../button';
import { isTemplate } from '../../services/is-template';
import { getViewMode } from '../../../common/selectors/basic-data-selector';
import { getIsDemoMode } from '../../../common/store/instance-values/instance-values-selectors';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import withTranslate from '../../hoc/with-translate';
import styles from '../button/button.scss';
import { keepFocus } from '../../services/keep-focus';

class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.autoLogin) {
      setTimeout(() => this.handleClick(), 100);
    }
  }

  handleClick() {
    const { viewMode, isDemoMode, openModal, requestLogin } = this.props;

    if (isDemoMode) {
      openModal(MODAL_TYPE_DEMO_MODE, { viewMode, isTemplate });
    } else {
      requestLogin();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Button className={classNames(styles.button, 'login-button')} onClick={this.handleClick}>
        {t('login-button.sign-up')}
      </Button>
    );
  }
}

LoginButton.propTypes = {
  autoLogin: PropTypes.bool,
  t: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  isDemoMode: PropTypes.bool,
  openModal: PropTypes.func,
  requestLogin: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  viewMode: getViewMode(state),
  isDemoMode: getIsDemoMode(state),
  isTemplate: isTemplate(getSectionUrl(state)),
  requestLogin: keepFocus(actions.requestLoginPromisified),
  openModal: actions.openModal,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(LoginButton);
