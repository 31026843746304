import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { PinIcon } from '../../components/icons/pin-icon';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { ShareIcon } from '../../components/icons/share-icon';
import { LockIcon } from '../../components/icons/lock-icon';
import { MoveIcon } from '../../components/icons/move-icon';
import { ReportIcon } from '../../components/icons/report-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { SubscribeIcon } from '../../components/icons/subscribe-icon';
import ActionButton from '../../components/action-button';
import { MODAL_TYPE_SHARE_POST } from '../../components/modals/share-post-modal/share-post-modal-type';
import { MODAL_TYPE_DELETE_POST } from '../../components/modals/delete-post-modal/delete-post-modal-type';
import { MODAL_TYPE_REPORT_POST } from '../../components/modals/report-post-modal/report-post-modal-type';
import { MODAL_TYPE_MOVE_POST } from '../../components/modals/move-post-modal/move-post-modal-type';
import ensureAuth from '../../hoc/ensure-auth';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { getCategory, getAllCategoryCount } from '../../selectors/categories-selectors';
import withAuth from '../../hoc/with-auth';
import { isEditor, isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import { FOLLOW_POST } from '../../constants/interactions';

const ActionButtonAuth = ensureAuth(ActionButton);

export class PostActions extends Component {
  constructor(props) {
    super(props);

    this.handlePin = this.handlePin.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
  }

  componentDidMount() {
    this.props.actionsOpened({ type: 'post' });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: 'post' });
  }

  handlePin() {
    const {
      post: { _id, isPinned },
      buttonClicked,
      pinPost,
      unpinPost,
    } = this.props;

    buttonClicked({ action: 'pin', flag: Number(!isPinned) });
    if (isPinned) {
      unpinPost(_id);
    } else {
      pinPost(_id);
    }
  }

  handleSubscription() {
    const {
      post: { _id, isSubscribed },
      buttonClicked,
      subscribe,
      unsubscribe,
    } = this.props;

    buttonClicked({ action: 'follow_post', flag: Number(!isSubscribed) });
    if (isSubscribed) {
      unsubscribe(_id);
    } else {
      subscribe(_id);
    }
  }

  handleComments() {
    const {
      post: { _id, isCommentsDisabled },
      buttonClicked,
      enablePostComments,
      disablePostComments,
    } = this.props;

    buttonClicked({ action: 'lock', flag: Number(!isCommentsDisabled) });
    if (isCommentsDisabled) {
      enablePostComments(_id);
    } else {
      disablePostComments(_id);
    }
  }

  showModal(type) {
    const props = {
      categoryId: this.props.category._id,
      categorySlug: this.props.category.slug,
      postId: this.props.post._id,
      postType: this.props.post.postType,
      postSlug: this.props.post.slug,
    };
    const { buttonClicked, openModal, focusOnCloseId, origin } = this.props;

    return () => {
      buttonClicked({ action: type });
      openModal(type, props, { focusOnCloseId, origin });
    };
  }

  navigateToEditPost = () => {
    const { buttonClicked, category, post, navigateWithinForum } = this.props;
    buttonClicked({ action: 'edit' });
    navigateWithinForum(`/${category.slug}/${post.slug}/edit`);
  };

  render() {
    const { canRender, post, categoryCount, t, forPublicUser, shouldShowShareButton } = this.props;
    const editButton = canRender('edit', 'post', post, can => (
      <ActionButton
        dataHook="post-actions__edit"
        onClick={can ? this.navigateToEditPost : forPublicUser(this.navigateToEditPost)}
      >
        <EditIcon />
        {t('post-actions.edit-post')}
      </ActionButton>
    ));
    const shareButton =
      shouldShowShareButton &&
      canRender('share', 'post', post, () => (
        <ActionButton
          dataHook="post-actions__share"
          onClick={this.showModal(MODAL_TYPE_SHARE_POST)}
        >
          <ShareIcon />
          {t('post-actions.share-post')}
        </ActionButton>
      ));
    const pinButton = canRender('pin', 'post', post, () => (
      <ActionButton dataHook="post-actions__pin" onClick={this.handlePin}>
        <PinIcon isPinned={post.isPinned} className="button-hover-color" />
        {post.isPinned ? t('post-actions.unpin-post') : t('post-actions.pin-post')}
      </ActionButton>
    ));
    const commentsButton = canRender('toggle-comments', 'post', post, () => (
      <ActionButton dataHook="post-actions__comments" onClick={this.handleComments}>
        <LockIcon isLocked={post.isCommentsDisabled} />
        {post.isCommentsDisabled
          ? t('post-actions.enable-commenting')
          : t('post-actions.disable-commenting')}
      </ActionButton>
    ));
    const moveButton =
      categoryCount > 1 &&
      canRender('move', 'post', post, () => (
        <ActionButton dataHook="post-actions__move" onClick={this.showModal(MODAL_TYPE_MOVE_POST)}>
          <MoveIcon />
          {t('post-actions.move-to-cateogory')}
        </ActionButton>
      ));
    const reportButton = canRender('report', 'post', post, can => (
      <ActionButton
        dataHook="post-actions__report"
        onClick={
          can
            ? this.showModal(MODAL_TYPE_REPORT_POST)
            : forPublicUser(this.showModal(MODAL_TYPE_REPORT_POST))
        }
      >
        <ReportIcon />
        {t('post-actions.report-post')}
      </ActionButton>
    ));
    const deleteButton = canRender('delete', 'post', post, () => (
      <ActionButton
        dataHook="post-actions__delete"
        onClick={this.showModal(MODAL_TYPE_DELETE_POST)}
      >
        <DeleteIcon />
        {t('post-actions.delete-post')}
      </ActionButton>
    ));
    const subscribeButton = canRender('subscribe', 'post', post, can => {
      const ActionComponent = can ? ActionButton : ActionButtonAuth;
      return (
        <ActionComponent
          actionDetails={{ action: FOLLOW_POST, args: [post._id] }}
          dataHook="post-actions__subscriptions"
          onClick={forPublicUser(this.handleSubscription)}
        >
          <SubscribeIcon isSubscribed={post.isSubscribed} className="button-hover-color" />
          {post.isSubscribed ? t('post-actions.unfollow') : t('post-actions.follow')}
        </ActionComponent>
      );
    });
    return (
      <div>
        {subscribeButton}
        {editButton}
        {shareButton}
        {pinButton}
        {commentsButton}
        {moveButton}
        {reportButton}
        {deleteButton}
      </div>
    );
  }
}

PostActions.propTypes = {
  forPublicUser: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  t: PropTypes.func,
  buttonClicked: PropTypes.func,
  post: PropTypes.object.isRequired,
  category: PropTypes.object,
  categoryCount: PropTypes.number,
  openModal: PropTypes.func,
  focusOnCloseId: PropTypes.string,
  pinPost: PropTypes.func,
  unpinPost: PropTypes.func,
  enablePostComments: PropTypes.func,
  disablePostComments: PropTypes.func,
  actionsOpened: PropTypes.func,
  actionsClosed: PropTypes.func,
  navigateWithinForum: PropTypes.func,
  canRender: PropTypes.func,
  shouldShowShareButton: PropTypes.bool.isRequired,
  origin: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, { post }, actions) => ({
  category: getCategory(state, post.categoryId),
  categoryCount: getAllCategoryCount(state),
  openModal: actions.openModal,
  subscribe: actions.subscribeToPost,
  unsubscribe: actions.unsubscribeFromPost,
  actionsOpened: actions.actionsOpened,
  actionsClosed: actions.actionsClosed,
  pinPost: actions.pinPost,
  unpinPost: actions.unpinPost,
  enablePostComments: actions.enablePostComments,
  disablePostComments: actions.disablePostComments,
  navigateWithinForum: actions.navigateWithinForum,
  buttonClicked: data => actions.buttonClicked({ name: 'action_click', type: 'post', ...data }),
  shouldShowShareButton: !isEditor(state) && !isPreview(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withPermissions,
  withTranslate,
)(PostActions);
