import moment from 'moment';
import 'moment/min/locales';
import { MOMENT_LOCALES, DATE_FORMATS } from '../constants/time';
import { compactNumber } from './format-number';

const I18N_NESTING_KEY_SEPARATOR = '$';

export default function createI18nConfig() {
  return {
    keySeparator: I18N_NESTING_KEY_SEPARATOR,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'number':
            return compactNumber(lng)(value);
          case 'timeRelative':
            return moment(value)
              .locale(MOMENT_LOCALES[lng])
              .fromNow();
          case 'date':
            return moment(value)
              .locale(MOMENT_LOCALES[lng])
              .format(DATE_FORMATS[lng]);
          case 'fullDate':
            return moment(value)
              .locale(MOMENT_LOCALES[lng])
              .format('ll');
          default:
            return value;
        }
      },
    },
  };
}
