import { find, map, get } from 'lodash';
import { createSelector } from 'reselect';

export const getAllCategories = state => state.categories;
export const getCategories = state => getAllCategories(state).filter(c => !c.parentId);

export const getAllCategoryCount = state => getAllCategories(state).length;
export const getCategoryCount = state => getCategories(state).length;

export const getSubcategories = (state, categoryId) =>
  getAllCategories(state).filter(c => c.parentId === categoryId);

const getCategoryId = (state, categoryId) => categoryId;

const getCategorySlug = (state, categorySlug) => categorySlug;

export const getCategory = createSelector(
  [getAllCategories, getCategoryId],
  (categories, categoryId) => find(categories, category => category._id === categoryId),
);

export const getParentCategory = (state, categoryId) => {
  const { parentId } = getCategory(state, categoryId) || {};
  return getCategory(state, parentId);
};

export const getCategoryBySlug = createSelector(
  [getAllCategories, getCategorySlug],
  (categories, slug) =>
    find(
      categories,
      category => category.slug === slug || (category.slugs && category.slugs.indexOf(slug) > -1),
    ),
);

export const getCategoryIdBySlug = (state, slug) =>
  get(getCategoryBySlug(state, slug), '_id', null);

export const getHighestRank = createSelector(
  [getAllCategories],
  categories => Math.max(-1, ...map(categories, category => category.rank)),
);
