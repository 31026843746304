import { get } from 'lodash';
import { createSelector } from 'reselect';
import { USER_PRIVACY_STATUS_PRIVATE } from '../../constants/user-privacy-status';

export const getCurrentUser = state => state.currentUser;

export const getCurrentUserId = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser._id,
);

export const getCurrentUserSiteMemberId = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser.siteMemberId,
);

export const getCurrentUserSlug = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser.slug,
);

export const getCurrentUserCounters = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser.counters,
);

export const isOwner = createSelector(
  [getCurrentUser],
  currentUser => {
    // we look at role so that it is consistent with ACL. This allows for contributors to act like site owners, create categories, add members, etc..
    return get(currentUser, 'permissions.role') === 'owner';
  },
);

export const isBlocked = createSelector(
  [getCurrentUser],
  currentUser => Boolean(currentUser && currentUser.isBlocked),
);

export const isPrivate = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser.privacyStatus === USER_PRIVACY_STATUS_PRIVATE,
);

export const getCurrentUserRemainingPosts = createSelector(
  [getCurrentUser],
  currentUser => currentUser && currentUser.remainingPosts,
);
