import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import withDirection, { withDirectionPropTypes, DIRECTIONS } from 'react-with-direction';
import RecentPostsListItem from '../recent-posts-list-item';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withCardBorderColor from '../../hoc/with-card-border-color';
import styles from './recent-post-carousel.scss';

const sliderProps = {
  arrows: false,
  className: styles.container,
  dots: false,
  useCSS: true,
  infinite: true,
  centerMode: true,
  centerPadding: '20px',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const itemClassName = classNames(
  styles.postWrapper,
  'forum-card-background-color',
  'recent-posts-list__list-item',
);

const RecentPostCarousel = ({
  direction,
  posts = [],
  borderColor,
  borderWidth,
  isPostNumbersEnabled,
  handleLikeClick,
}) => (
  <div dir="ltr">
    <Slider {...sliderProps}>
      {map(posts, post => (
        <div
          className={styles.slide}
          key={post._id}
          dir={direction === DIRECTIONS.RTL ? 'rtl' : undefined}
        >
          <div
            className={classNames(itemClassName, !isPostNumbersEnabled && styles.withoutFooter)}
            style={{ borderColor, borderWidth }}
            key={post._id}
          >
            <RecentPostsListItem
              post={post}
              isPostNumbersEnabled={isPostNumbersEnabled}
              handleLikeClick={handleLikeClick}
              withCover={false}
            />
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

RecentPostCarousel.propTypes = {
  ...withDirectionPropTypes,
  posts: PropTypes.array.isRequired,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  handleLikeClick: PropTypes.func,
};

export default flowRight(
  withCardBorderColor,
  withCardBorderWidth,
  withDirection,
)(RecentPostCarousel);
