import React from 'react';
import PropTypes from 'prop-types';

export const TopCommentIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="17"
    viewBox="0 0 12 17"
    style={{
      fillRule: 'evenodd',
      fill: color,
    }}
    {...props}
  >
    <path
      d="M6,0 C2.6862915,-2.02906125e-16 4.05812251e-16,2.6862915 0,6 C-4.05812251e-16,9.3137085 2.6862915,12 6,12 C9.3137085,12 12,9.3137085 12,6 C12,4.40870106 11.367859,2.88257758 10.2426407,1.75735931 C9.11742242,0.632141042 7.59129894,9.74389576e-17 6,0 Z M9.94,5 L8.09,6.7 L8.53,9 C8.53886396,9.05951419 8.5113067,9.11856545 8.46,9.15 C8.42742052,9.16002564 8.39257948,9.16002564 8.36,9.15 L8.28,9.15 L6,8.1 L3.7,9.21 C3.64690295,9.23844491 3.58309705,9.23844491 3.53,9.21 C3.4932715,9.19101483 3.46600793,9.1577116 3.45464955,9.11795727 C3.44329117,9.07820295 3.44884533,9.03552322 3.47,9 L3.91,6.7 L2,5 C1.96652167,4.95192041 1.96652167,4.88807959 2,4.84 C2.02165446,4.78530252 2.07157805,4.74689975 2.13,4.74 L4.7,4.44 L5.85,2.31 C5.87954496,2.25460321 5.93721697,2.22 6,2.22 C6.06278303,2.22 6.12045504,2.25460321 6.15,2.31 L7.29,4.44 L9.85,4.78 C9.91060733,4.78742971 9.96331546,4.82507838 9.99,4.88 C9.99243292,4.92551947 9.97403463,4.96967535 9.94,5 Z"
      id="Shape"
    />
    <path
      d="M6,13 C4.56923057,12.9986614 3.17323082,12.5589215 2,11.74 L2,16.5 C1.99787124,16.6756399 2.08806353,16.839515 2.23758934,16.9316884 C2.38711515,17.0238619 2.57404123,17.0308132 2.73,16.95 L5.73,15.39 C5.87423031,15.3152793 6.04576969,15.3152793 6.19,15.39 L9.19,16.95 C9.34849903,17.0776976 9.56858666,17.096571 9.74651285,16.9977231 C9.92443903,16.8988752 10.0246872,16.7020376 10,16.5 L10,11.74 C8.82676918,12.5589215 7.43076943,12.9986614 6,13 L6,13 Z"
      id="Shape"
    />
  </svg>
);

TopCommentIcon.propTypes = {
  color: PropTypes.string,
};
