import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from '../../common/components/runtime-context';
import { MODAL_TYPE_DEMO_MODE } from '../components/modals/demo-modal/demo-modal-type';
import createHocName from '../services/create-hoc-name';
import { getViewMode } from '../../common/store/basic-params/basic-params-selectors';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import withAuth from './with-auth';

const NAME = 'PreventClickInDemoMode';

export default function preventClickInDemoMode(WrappedComponent) {
  class PreventClickInDemoMode extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    handleClick = event => {
      const { isDemoMode, viewMode, onClick, openModal } = this.props;

      if (isDemoMode) {
        openModal(MODAL_TYPE_DEMO_MODE, { viewMode });
      } else {
        onClick && onClick(event);
      }
    };

    render() {
      return <WrappedComponent {...this.props} onClick={this.handleClick} />;
    }
  }

  PreventClickInDemoMode.propTypes = {
    isDemoMode: PropTypes.bool.isRequired,
    viewMode: PropTypes.string.isRequired,
    openModal: PropTypes.func,
    onClick: PropTypes.func,
  };

  hoistNonReactStatics(PreventClickInDemoMode, WrappedComponent);

  const mapRuntimeToProps = (state, ownProps, actions) => ({
    viewMode: getViewMode(state),
    isDemoMode: getIsDemoMode(state),
    openModal: actions.openModal,
  });

  return flowRight(
    connect(mapRuntimeToProps),
    withAuth,
  )(PreventClickInDemoMode);
}
