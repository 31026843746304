import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './empty-state.scss';
import withCardBorderColor from '../../hoc/with-card-border-color';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import classNames from 'classnames';

const containerClassName = classNames(styles.container);

const containerInnerClassName = classNames(styles.containerInner, 'forum-card-background-color');

const containerInnerLeftClassName = classNames(
  'forum-post-title-font',
  'forum-post-title-color',
  styles.containerInnerLeft,
);

const EmptyState = ({ title, content, borderColor, borderWidth, button, titleFontClassName }) => (
  <div className={containerClassName}>
    <div className={containerInnerClassName} style={{ borderColor, borderWidth }}>
      <div className={containerInnerLeftClassName}>
        <div className={styles.containerInnerLeftTitle}>{title}</div>
        <div className={styles.containerInnerLeftContent}>{content}</div>
      </div>
      <div className={classNames(styles.containerInnerRight, titleFontClassName)}>
        {button ? button : null}
      </div>
    </div>
    <span />
  </div>
);

EmptyState.defaultProps = {
  titleFontClassName: 'forum-post-title-font',
};

EmptyState.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  button: PropTypes.element,
  titleFontClassName: PropTypes.string,
};

export default flowRight(
  withCardBorderColor,
  withCardBorderWidth,
)(EmptyState);
