import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import PostLink from '../post-link';
import PostHeader from '../../../app/components/post-header';
import PostTitle from '../../../app/components/post-title';
import PostItemFooter from '../post-item-footer';
import styles from './recent-posts-item.scss';

const RecentPostsItem = ({
  post,
  titleFontClassName,
  titleFontColorClassName,
  withCover,
  isPostNumbersEnabled,
  handleLikeClick,
  forPublicUser,
  isViewsCountEnabled,
}) => {
  const renderTitle = (
    <div
      className={classNames(titleFontClassName, titleFontColorClassName)}
      data-hook="recent-post__title"
    >
      <PostLink post={post} className={classNames(styles.link, 'forum-link-hover-color')}>
        <PostTitle
          className={classNames(styles.title)}
          title={post.title}
          post={post}
          type={PostTitle.MEDIUM}
          isQACategoriesEnabled
        />
      </PostLink>
    </div>
  );

  const renderHeader = (
    <div
      className={classNames(
        styles.header,
        titleFontClassName,
        titleFontColorClassName,
        'icon-fill',
      )}
    >
      <PostHeader
        type={PostHeader.RECENT_POSTS_WIDGET}
        post={post}
        showPinIcon={false}
        showLockIcon={false}
        showDateBelowAvatar={true}
        showMoreButton={false}
        isQACategoriesEnabled
        showMemberCard={false}
      />
    </div>
  );

  const renderFooter = (
    <PostItemFooter
      {...{
        post,
        handleLikeClick,
        titleFontClassName,
        titleFontColorClassName,
        forPublicUser,
        isViewsCountEnabled,
        isQACategoriesEnabled: true,
      }}
    />
  );

  return (
    <div className={classNames(styles.main)}>
      <div className={(styles.contentRow, styles.content)}>
        {/*TODO: removed at current QACategories stage, align with UX in the future
        withCover && (
          <div className={styles.cover}>
            <PostItemCover post={post} type={{ fixedHeight: 70, fixedWidth: 70 }} />
          </div>
        )
        */}
        {renderHeader}
        {renderTitle}
      </div>
      <div>{isPostNumbersEnabled && renderFooter}</div>
    </div>
  );
};

RecentPostsItem.propTypes = {
  post: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  titleFontColorClassName: PropTypes.string.isRequired,
  navigation: PropTypes.object,
  withCover: PropTypes.bool.isRequired,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  handleLikeClick: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
};

export default RecentPostsItem;
