import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TimeAgo from '../time-ago';
import { BulletSeparator, A11yDashSeparator } from '../separator';
import withTranslate from '../../hoc/with-translate';
import styles from './quick-user-info.scss';
import { getBadgeById } from '../../selectors/badge-selectors';
import { connect } from '../../../common/components/runtime-context';
import { getIsBadgesEnabled } from '../../selectors/app-settings-selectors';
import Badge from '../../containers/badge/badge';

function QuickUserInfo({
  date,
  showDate,
  showBadges,
  showFirstSeparator,
  membersBadge,
  isTinyBadge,
  hideBadgeBackground,
  isBadgesEnabled,
  noMargin,
  truncateBadgeTitle,
  t,
}) {
  const renderTime = () => <TimeAgo time={date} className={styles.date} />;

  const renderSeparators = () => (
    <div className={styles.separator}>
      <A11yDashSeparator />
      <BulletSeparator />
    </div>
  );

  const renderBadge = () => {
    if (membersBadge)
      return (
        <div className={styles.badge}>
          {showDate && showFirstSeparator && renderSeparators()}
          <div className={hideBadgeBackground && styles.badgeNoBackground}>
            <Badge
              badge={membersBadge}
              hideBackground={hideBadgeBackground}
              size={isTinyBadge ? 'tiny' : 'small'}
              truncateTitle={truncateBadgeTitle}
            />
          </div>
          {showDate && renderSeparators()}
        </div>
      );
  };

  return (
    <span
      className={classNames([
        styles.container,
        showBadges && styles.badge,
        !noMargin && styles.extraMarginTop,
      ])}
      data-hook="quick-user-info"
    >
      {showBadges && isBadgesEnabled && renderBadge()}
      {showDate && renderTime()}
    </span>
  );
}

QuickUserInfo.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  date: PropTypes.string,
  t: PropTypes.func,
  showDate: PropTypes.bool,
  showBadges: PropTypes.bool,
  showFirstSeparator: PropTypes.bool,
  membersBadge: PropTypes.object,
  isTinyBadge: PropTypes.bool,
  hideBadgeBackground: PropTypes.bool,
  isBadgesEnabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  truncateBadgeTitle: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const isBadgesEnabled = getIsBadgesEnabled(state, host.style);
  return {
    isBadgesEnabled,
    membersBadge: isBadgesEnabled && getBadgeById(state, get(ownProps, 'user.badges[0]', '')),
  };
};

QuickUserInfo.defaultProps = {
  showBadges: true,
  showFirstSeparator: false,
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(QuickUserInfo);
