// Layout params

export const LAYOUT_TYPE = 'layout-type';
export const LAYOUT_TYPE_PATH = `styleParams.numbers.${LAYOUT_TYPE}`;

export const LAYOUT_TYPE_MAIN_PAGE = 'layout-mainPage-type';
export const LAYOUT_TYPE_MAIN_PAGE_PATH = `styleParams.numbers.${LAYOUT_TYPE_MAIN_PAGE}`;

export const CARD_SIZE = 'layout-cardSize';
export const CARD_SIZE_PATH = `styleParams.numbers.${CARD_SIZE}`;

export const CATEGORY_TEXT_ALIGNMENT = 'layout-tagTextAlignment';
export const CATEGORY_TEXT_ALIGNMENT_PATH = `styleParams.numbers.${CATEGORY_TEXT_ALIGNMENT}`;

export const MAIN_PAGE_TEXT_ALIGNMENT = 'layout-mainPage-tagTextAlignment';
export const MAIN_PAGE_TEXT_ALIGNMENT_PATH = `styleParams.numbers.${MAIN_PAGE_TEXT_ALIGNMENT}`;

export const IS_MAIN_PAGE_HEADER_ENABLED = 'layout-isMainPageHeaderEnabled';
export const IS_MAIN_PAGE_HEADER_ENABLED_PATH = `styleParams.booleans.${IS_MAIN_PAGE_HEADER_ENABLED}`;

export const FORUM_STRUCTURE = 'layout-forumStructure';
export const FORUM_STRUCTURE_PATH = `styleParams.numbers.${FORUM_STRUCTURE}`;

export const IS_SHOW_SUBCATEGORIES_ENABLED = 'layout-isShowSubcategoriesEnabled';
export const IS_SHOW_SUBCATEGORIES_ENABLED_PATH = `styleParams.booleans.${IS_SHOW_SUBCATEGORIES_ENABLED}`;

export const IS_FOOTER_POSTS_ENABLED = 'layout-isFooterPostsEnabled';
export const IS_FOOTER_POSTS_ENABLED_PATH = `styleParams.booleans.${IS_FOOTER_POSTS_ENABLED}`;

export const IS_HEADER_ENABLED = 'layout-isHeaderEnabled';
export const IS_HEADER_ENABLED_PATH = `styleParams.booleans.${IS_HEADER_ENABLED}`;

export const HEADER_HEIGHT = 'layout-headerHeight';
export const HEADER_HEIGHT_PATH = `styleParams.numbers.${HEADER_HEIGHT}`;

export const MAIN_PAGE_HEADER_HEIGHT = 'layout-mainPage-headerHeight';
export const MAIN_PAGE_HEADER_HEIGHT_PATH = `styleParams.numbers.${MAIN_PAGE_HEADER_HEIGHT}`;

export const HEADER_TEXT_COLOR = 'header-textColor';
export const HEADER_TEXT_COLOR_PATH = `styleParams.colors.${HEADER_TEXT_COLOR}`;

// Settings
export const POST_SORTING = 'settings-postSorting';
export const POST_SORTING_PATH = `styleParams.numbers.${POST_SORTING}`;

// Post design params

export const APP_TEXT_COLOR = 'post-textColor';
export const APP_TEXT_COLOR_PATH = `styleParams.colors.${APP_TEXT_COLOR}`;

export const BORDER_WIDTH_SMALL = 'post-borderWidthSmall';
export const BORDER_WIDTH_SMALL_PATH = `styleParams.numbers.${BORDER_WIDTH_SMALL}`;

export const BORDER_WIDTH_MEDIUM = 'post-borderWidthMedium';
export const BORDER_WIDTH_MEDIUM_PATH = `styleParams.numbers.${BORDER_WIDTH_MEDIUM}`;

export const BORDER_WIDTH_LARGE = 'post-borderWidthLarge';
export const BORDER_WIDTH_LARGE_PATH = `styleParams.numbers.${BORDER_WIDTH_LARGE}`;

export const BORDER_WIDTH_CLASSIC = 'post-borderWidthClassic';
export const BORDER_WIDTH_CLASSIC_PATH = `styleParams.numbers.${BORDER_WIDTH_CLASSIC}`;

export const BORDER_WIDTH_COMPACT = 'post-borderWidthCompact';
export const BORDER_WIDTH_COMPACT_PATH = `styleParams.numbers.${BORDER_WIDTH_COMPACT}`;

export const CARD_BACKGROUND_COLOR = 'post-cardBackgroundColor';
export const CARD_BACKGROUND_COLOR_PATH = `styleParams.colors.${CARD_BACKGROUND_COLOR}`;

export const APP_BACKGROUND_COLOR = 'post-appBackgroundColor';
export const APP_BACKGROUND_COLOR_PATH = `styleParams.colors.${APP_BACKGROUND_COLOR}`;

export const LINKS_HASHTAGS_COLOR = 'button-linksHashtags';
export const LINKS_HASHTAGS_COLOR_PATH = `styleParams.colors.${LINKS_HASHTAGS_COLOR}`;

// Button design params

export const BUTTON_COLOR = 'button-color';
export const BUTTON_COLOR_PATH = `styleParams.colors.${BUTTON_COLOR}`;

export const BUTTON_TEXT_COLOR = 'button-primaryTextColor';
export const BUTTON_TEXT_COLOR_PATH = `styleParams.colors.${BUTTON_TEXT_COLOR}`;

export const SECONDARY_ICON_COLOR = 'icon-secondaryColor';
export const SECONDARY_ICON_COLOR_PATH = `styleParams.colors.${SECONDARY_ICON_COLOR}`;

// Widget params

export const WIDGET_SIGN_UP_BUTTON_TYPE = 'widget-signUpButtonType';
export const WIDGET_SIGN_UP_BUTTON_TYPE_PATH = `styleParams.numbers.${WIDGET_SIGN_UP_BUTTON_TYPE}`;

export const WIDGET_SIGN_UP_BUTTON_BORDER_COLOR = 'widget-signUpButtonBorderColor';
export const WIDGET_SIGN_UP_BUTTON_BORDER_COLOR_PATH = `styleParams.colors.${WIDGET_SIGN_UP_BUTTON_BORDER_COLOR}`;

export const WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR = 'widget-signUpButtonBackgroundColor';
export const WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR_PATH = `styleParams.colors.${WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR}`;

export const WIDGET_SIGN_UP_BUTTON_FONT = 'widget-signUpButtonFont';
export const WIDGET_SIGN_UP_BUTTON_FONT_PATH = `styleParams.fonts.${WIDGET_SIGN_UP_BUTTON_FONT}`;

export const WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR = 'widget-signUpButtonPrimaryColor';
export const WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR_PATH = `styleParams.fonts.${WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR}`;

export const WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR = 'widget-signUpButtonSecondaryColor';
export const WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR_PATH = `styleParams.fonts.${WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR}`;

export const WIDGET_NOTIFICATION_ICON_COLOR = 'widget-notificationIconColor';
export const WIDGET_NOTIFICATION_ICON_COLOR_PATH = `styleParams.colors.${WIDGET_NOTIFICATION_ICON_COLOR}`;

export const WIDGET_NOTIFICATION_BUBBLE_COLOR = 'widget-notificationBubbleColor';
export const WIDGET_NOTIFICATION_BUBBLE_COLOR_PATH = `styleParams.colors.${WIDGET_NOTIFICATION_BUBBLE_COLOR}`;

export const WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR = 'widget-notificationBubbleTextColor';
export const WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR_PATH = `styleParams.colors.${WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR}`;

export const WIDGET_AVATAR_BORDER_COLOR = 'widget-avatarBorderColor';
export const WIDGET_AVATAR_BORDER_COLOR_PATH = `styleParams.colors.${WIDGET_AVATAR_BORDER_COLOR}`;

// Forum widget params

export const IS_LOGIN_WIDGET_ENABLED = 'widget-isLoginButtonEnabled';
export const IS_LOGIN_WIDGET_ENABLED_PATH = `styleParams.booleans.${IS_LOGIN_WIDGET_ENABLED}`;

// Spam filter params

export const SPAM_FILTER_STOP_WORDS = 'spamFilter-stopWords';
export const SPAM_FILTER_WORDS_FILTER = 'spamFilter-wordsFilter';

// RCE Plugins

export const IS_RCE_IMAGE_ENABLED = 'editor-isImageEnabled';
export const IS_RCE_IMAGE_ENABLED_PATH = `styleParams.booleans.${IS_RCE_IMAGE_ENABLED}`;

export const IS_RCE_VIDEO_ENABLED = 'editor-isVideoEnabled';
export const IS_RCE_VIDEO_ENABLED_PATH = `styleParams.booleans.${IS_RCE_VIDEO_ENABLED}`;

export const IS_RCE_DIVIDER_ENABLED = 'editor-isDividerEnabled';
export const IS_RCE_DIVIDER_ENABLED_PATH = `styleParams.booleans.${IS_RCE_DIVIDER_ENABLED}`;

export const IS_RCE_HTML_ENABLED = 'editor-isHtmlEnabled';
export const IS_RCE_HTML_ENABLED_PATH = `styleParams.booleans.${IS_RCE_HTML_ENABLED}`;

export const IS_RCE_CODE_BLOCK_ENABLED = 'editor-isCodeBlockEnabled';
export const IS_RCE_CODE_BLOCK_ENABLED_PATH = `styleParams.booleans.${IS_RCE_CODE_BLOCK_ENABLED}`;

export const IS_RCE_GIPHY_ENABLED = 'editor-isGiphyEnabled';
export const IS_RCE_GIPHY_ENABLED_PATH = `styleParams.booleans.${IS_RCE_GIPHY_ENABLED}`;

export const IS_RCE_FILE_UPLOAD_ENABLED = 'editor-isFileUploadEnabled';
export const IS_RCE_FILE_UPLOAD_ENABLED_PATH = `styleParams.booleans.${IS_RCE_FILE_UPLOAD_ENABLED}`;

export const IS_VIEWS_COUNT_ENABLED = 'site-isViewsCountEnabled';
export const IS_VIEWS_COUNT_ENABLED_PATH = `styleParams.booleans.${IS_VIEWS_COUNT_ENABLED}`;

export const IS_LIST_HASHTAGS_ENABLED = 'site-isListHashtagsEnabled';
export const IS_LIST_HASHTAGS_ENABLED_PATH = `styleParams.booleans.${IS_LIST_HASHTAGS_ENABLED}`;

export const IS_SOCIAL_SHARING_LINKS_ENABLED = 'site-isSocialSharingLinksEnabled';

export const IS_BADGES_ENABLED = 'site-isBadgesEnabled';
export const IS_BADGES_ENABLED_PATH = `styleParams.booleans.${IS_BADGES_ENABLED}`;

export const IS_SOCIAL_SHARING_LINKS_ENABLED_PATH = `styleParams.booleans.${IS_SOCIAL_SHARING_LINKS_ENABLED}`;
